exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-bussiness-rescued-brink-collapse-training-programme-js": () => import("./../../../src/pages/blog/bussiness-rescued-brink-collapse-training-programme.js" /* webpackChunkName: "component---src-pages-blog-bussiness-rescued-brink-collapse-training-programme-js" */),
  "component---src-pages-blog-itc-and-partners-launch-25-million-peace-building-project-2023-2025-promote-social-cohesion-js": () => import("./../../../src/pages/blog/itc-and-partners-launch-25-million-peace-building-project-2023-2025-promote-social-cohesion.js" /* webpackChunkName: "component---src-pages-blog-itc-and-partners-launch-25-million-peace-building-project-2023-2025-promote-social-cohesion-js" */),
  "component---src-pages-blog-itc-wraps-training-30-women-informal-cross-border-traders-trade-rules-and-entrepreneurship-js": () => import("./../../../src/pages/blog/itc-wraps-training-30-women-informal-cross-border-traders-trade-rules-and-entrepreneurship.js" /* webpackChunkName: "component---src-pages-blog-itc-wraps-training-30-women-informal-cross-border-traders-trade-rules-and-entrepreneurship-js" */),
  "component---src-pages-blog-jobs-skills-and-finance-programme-making-dreams-come-true-js": () => import("./../../../src/pages/blog/jobs-skills-and-finance-programme-making-dreams-come-true.js" /* webpackChunkName: "component---src-pages-blog-jobs-skills-and-finance-programme-making-dreams-come-true-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-kankurang-lives-js": () => import("./../../../src/pages/blog/kankurang-lives.js" /* webpackChunkName: "component---src-pages-blog-kankurang-lives-js" */),
  "component---src-pages-blog-migrant-returnees-first-time-participation-trade-fair-opens-doors-opportunities-her-business-js": () => import("./../../../src/pages/blog/migrant-returnees-first-time-participation-trade-fair-opens-doors-opportunities-her-business.js" /* webpackChunkName: "component---src-pages-blog-migrant-returnees-first-time-participation-trade-fair-opens-doors-opportunities-her-business-js" */),
  "component---src-pages-blog-opportunity-births-new-business-jalima-js": () => import("./../../../src/pages/blog/opportunity-births-new-business-jalima.js" /* webpackChunkName: "component---src-pages-blog-opportunity-births-new-business-jalima-js" */),
  "component---src-pages-blog-passion-hairdressing-turns-profession-js": () => import("./../../../src/pages/blog/passion-hairdressing-turns-profession.js" /* webpackChunkName: "component---src-pages-blog-passion-hairdressing-turns-profession-js" */),
  "component---src-pages-blog-poultry-gold-mine-my-backyard-js": () => import("./../../../src/pages/blog/poultry-gold-mine-my-backyard.js" /* webpackChunkName: "component---src-pages-blog-poultry-gold-mine-my-backyard-js" */),
  "component---src-pages-blog-skye-20-launched-gambia-js": () => import("./../../../src/pages/blog/skye-20-launched-gambia.js" /* webpackChunkName: "component---src-pages-blog-skye-20-launched-gambia-js" */),
  "component---src-pages-blog-women-farmers-embrace-best-agricultural-practices-improve-income-generation-js": () => import("./../../../src/pages/blog/women-farmers-embrace-best-agricultural-practices-improve-income-generation.js" /* webpackChunkName: "component---src-pages-blog-women-farmers-embrace-best-agricultural-practices-improve-income-generation-js" */),
  "component---src-pages-blog-yep-and-global-initiative-decent-jobs-youth-js": () => import("./../../../src/pages/blog/yep-and-global-initiative-decent-jobs-youth.js" /* webpackChunkName: "component---src-pages-blog-yep-and-global-initiative-decent-jobs-youth-js" */),
  "component---src-pages-core-team-js": () => import("./../../../src/pages/core-team.js" /* webpackChunkName: "component---src-pages-core-team-js" */),
  "component---src-pages-event-handing-over-ceremony-of-agro-processing-js": () => import("./../../../src/pages/event/handing-over-ceremony-of-agro-processing.js" /* webpackChunkName: "component---src-pages-event-handing-over-ceremony-of-agro-processing-js" */),
  "component---src-pages-event-national-youth-agribusiness-and-tourism-expo-js": () => import("./../../../src/pages/event/national-youth-agribusiness-and-tourism-expo.js" /* webpackChunkName: "component---src-pages-event-national-youth-agribusiness-and-tourism-expo-js" */),
  "component---src-pages-event-seed-stars-banjul-js": () => import("./../../../src/pages/event/seed-stars-banjul.js" /* webpackChunkName: "component---src-pages-event-seed-stars-banjul-js" */),
  "component---src-pages-event-she-trade-launch-investment-tank-day-js": () => import("./../../../src/pages/event/she-trade-launch-investment-tank-day.js" /* webpackChunkName: "component---src-pages-event-she-trade-launch-investment-tank-day-js" */),
  "component---src-pages-event-tekki-fii-opportunities-info-session-js": () => import("./../../../src/pages/event/tekki-fii-opportunities-info-session.js" /* webpackChunkName: "component---src-pages-event-tekki-fii-opportunities-info-session-js" */),
  "component---src-pages-event-women-leadership-forum-2nd-edition-js": () => import("./../../../src/pages/event/women-leadership-forum-2nd-edition.js" /* webpackChunkName: "component---src-pages-event-women-leadership-forum-2nd-edition-js" */),
  "component---src-pages-event-world-enterpreneurs-day-2020-js": () => import("./../../../src/pages/event/world-enterpreneurs-day-2020.js" /* webpackChunkName: "component---src-pages-event-world-enterpreneurs-day-2020-js" */),
  "component---src-pages-event-world-export-development-forum-2018-js": () => import("./../../../src/pages/event/world-export-development-forum-2018.js" /* webpackChunkName: "component---src-pages-event-world-export-development-forum-2018-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-gambia-itb-berlin-2019-js": () => import("./../../../src/pages/gambia-itb-berlin-2019.js" /* webpackChunkName: "component---src-pages-gambia-itb-berlin-2019-js" */),
  "component---src-pages-help-desk-js": () => import("./../../../src/pages/help-desk.js" /* webpackChunkName: "component---src-pages-help-desk-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-media-handover-personal-protective-equipment-ministry-health-js": () => import("./../../../src/pages/media/handover-personal-protective-equipment-ministry-health.js" /* webpackChunkName: "component---src-pages-media-handover-personal-protective-equipment-ministry-health-js" */),
  "component---src-pages-media-js": () => import("./../../../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-media-library-sector-assessments-js": () => import("./../../../src/pages/media/library/sector-assessments.js" /* webpackChunkName: "component---src-pages-media-library-sector-assessments-js" */),
  "component---src-pages-media-post-eight-js": () => import("./../../../src/pages/media-post-eight.js" /* webpackChunkName: "component---src-pages-media-post-eight-js" */),
  "component---src-pages-media-post-five-js": () => import("./../../../src/pages/media-post-five.js" /* webpackChunkName: "component---src-pages-media-post-five-js" */),
  "component---src-pages-media-post-four-js": () => import("./../../../src/pages/media-post-four.js" /* webpackChunkName: "component---src-pages-media-post-four-js" */),
  "component---src-pages-media-post-nine-js": () => import("./../../../src/pages/media-post-nine.js" /* webpackChunkName: "component---src-pages-media-post-nine-js" */),
  "component---src-pages-media-post-seven-js": () => import("./../../../src/pages/media-post-seven.js" /* webpackChunkName: "component---src-pages-media-post-seven-js" */),
  "component---src-pages-media-post-six-js": () => import("./../../../src/pages/media-post-six.js" /* webpackChunkName: "component---src-pages-media-post-six-js" */),
  "component---src-pages-media-youth-and-trade-roadmaps-js": () => import("./../../../src/pages/media/youth-and-trade-roadmaps.js" /* webpackChunkName: "component---src-pages-media-youth-and-trade-roadmaps-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-opportunities-js": () => import("./../../../src/pages/opportunities.js" /* webpackChunkName: "component---src-pages-opportunities-js" */),
  "component---src-pages-opportunity-call-multimedia-companies-register-our-platform-js": () => import("./../../../src/pages/opportunity/call-multimedia-companies-register-our-platform.js" /* webpackChunkName: "component---src-pages-opportunity-call-multimedia-companies-register-our-platform-js" */),
  "component---src-pages-opportunity-terms-reference-capacity-building-public-procurement-women-owned-business-js": () => import("./../../../src/pages/opportunity/terms-reference-capacity-building-public-procurement-women-owned-business.js" /* webpackChunkName: "component---src-pages-opportunity-terms-reference-capacity-building-public-procurement-women-owned-business-js" */),
  "component---src-pages-opportunity-terms-reference-develop-standard-operating-procedures-one-stop-shop-entrepreneurship-centreossec-farafenni-js": () => import("./../../../src/pages/opportunity/terms-reference-develop-standard-operating-procedures-one-stop-shop-entrepreneurship-centreossec-farafenni.js" /* webpackChunkName: "component---src-pages-opportunity-terms-reference-develop-standard-operating-procedures-one-stop-shop-entrepreneurship-centreossec-farafenni-js" */),
  "component---src-pages-opportunity-terms-reference-provision-architectural-services-establishment-safe-space-farafenni-north-bank-region-gambia-js": () => import("./../../../src/pages/opportunity/terms-reference-provision-architectural-services-establishment-safe-space-farafenni-north-bank-region-gambia.js" /* webpackChunkName: "component---src-pages-opportunity-terms-reference-provision-architectural-services-establishment-safe-space-farafenni-north-bank-region-gambia-js" */),
  "component---src-pages-opportunity-terms-reference-upskilling-training-youth-and-women-food-processors-nbr-and-lrr-js": () => import("./../../../src/pages/opportunity/terms-reference-upskilling-training-youth-and-women-food-processors-nbr-and-lrr.js" /* webpackChunkName: "component---src-pages-opportunity-terms-reference-upskilling-training-youth-and-women-food-processors-nbr-and-lrr-js" */),
  "component---src-pages-success-stories-js": () => import("./../../../src/pages/success-stories.js" /* webpackChunkName: "component---src-pages-success-stories-js" */),
  "component---src-pages-tool-2022-trade-fair-booklet-js": () => import("./../../../src/pages/tool/2022-trade-fair-booklet.js" /* webpackChunkName: "component---src-pages-tool-2022-trade-fair-booklet-js" */),
  "component---src-pages-tool-destination-janjangbureh-brochure-js": () => import("./../../../src/pages/tool/destination-janjangbureh-brochure.js" /* webpackChunkName: "component---src-pages-tool-destination-janjangbureh-brochure-js" */),
  "component---src-pages-tool-gambia-business-coaching-framework-js": () => import("./../../../src/pages/tool/gambia-business-coaching-framework.js" /* webpackChunkName: "component---src-pages-tool-gambia-business-coaching-framework-js" */),
  "component---src-pages-tool-gambia-tech-start-directory-js": () => import("./../../../src/pages/tool/gambia-tech-start-directory.js" /* webpackChunkName: "component---src-pages-tool-gambia-tech-start-directory-js" */),
  "component---src-pages-tool-gambian-tech-tech-start-directory-2021-js": () => import("./../../../src/pages/tool/gambian-tech-tech-start-directory-2021.js" /* webpackChunkName: "component---src-pages-tool-gambian-tech-tech-start-directory-2021-js" */),
  "component---src-pages-tool-hidden-gambia-photo-booklet-js": () => import("./../../../src/pages/tool/hidden-gambia-photo-booklet.js" /* webpackChunkName: "component---src-pages-tool-hidden-gambia-photo-booklet-js" */),
  "component---src-pages-tool-ninki-nanka-trail-product-manual-js": () => import("./../../../src/pages/tool/ninki-nanka-trail-product-manual.js" /* webpackChunkName: "component---src-pages-tool-ninki-nanka-trail-product-manual-js" */),
  "component---src-pages-tool-rapid-assessment-small-scale-cross-border-trade-and-migration-along-trans-gambia-transport-corridor-js": () => import("./../../../src/pages/tool/rapid-assessment-small-scale-cross-border-trade-and-migration-along-trans-gambia-transport-corridor.js" /* webpackChunkName: "component---src-pages-tool-rapid-assessment-small-scale-cross-border-trade-and-migration-along-trans-gambia-transport-corridor-js" */),
  "component---src-pages-tool-tekki-fii-brochure-20-js": () => import("./../../../src/pages/tool/tekki-fii-brochure-20.js" /* webpackChunkName: "component---src-pages-tool-tekki-fii-brochure-20-js" */),
  "component---src-pages-tool-tekki-fii-frequently-asked-questions-js": () => import("./../../../src/pages/tool/tekki-fii-frequently-asked-questions.js" /* webpackChunkName: "component---src-pages-tool-tekki-fii-frequently-asked-questions-js" */),
  "component---src-pages-tool-tekki-fii-programme-result-brochure-js": () => import("./../../../src/pages/tool/tekki-fii-programme-result-brochure.js" /* webpackChunkName: "component---src-pages-tool-tekki-fii-programme-result-brochure-js" */),
  "component---src-pages-tool-tekki-fii-returnee-flyer-js": () => import("./../../../src/pages/tool/tekki-fii-returnee-flyer.js" /* webpackChunkName: "component---src-pages-tool-tekki-fii-returnee-flyer-js" */),
  "component---src-pages-tool-tfgi-youth-pavilion-booklet-js": () => import("./../../../src/pages/tool/tfgi-youth-pavilion-booklet.js" /* webpackChunkName: "component---src-pages-tool-tfgi-youth-pavilion-booklet-js" */),
  "component---src-pages-tool-yep-narrative-reports-js": () => import("./../../../src/pages/tool/yep-narrative-reports.js" /* webpackChunkName: "component---src-pages-tool-yep-narrative-reports-js" */),
  "component---src-pages-tool-youth-and-trade-roadmap-js": () => import("./../../../src/pages/tool/youth-and-trade-roadmap.js" /* webpackChunkName: "component---src-pages-tool-youth-and-trade-roadmap-js" */),
  "component---src-pages-tools-js": () => import("./../../../src/pages/tools.js" /* webpackChunkName: "component---src-pages-tools-js" */)
}

